<template>
    <div class="container mb-5">
        <p v-if="msgErro == 'formato'" style="color: red">Erro: o arquivo selecionado não é uma imagem</p>
        <p v-if="msgErro == 'tamanho'" style="color: red">Erro: o arquivo selecionado é maior que 1 Mb</p>

        <div v-if="!showMode">
            <div v-if="item == 'item_4_1'">
                <b-button @click="selectFile()" v-if="!uploadEnd && !uploading" variant="warning">
                    Adicionar imagem de Representação (.png, .jpg, .jpeg)
                    <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                </b-button>
            </div>

            <div v-if="item == 'item_10'">
                <b-button @click="selectFile()" v-if="!uploadEnd && !uploading" variant="warning">
                    Adicionar imagem da assinatura (.png, .jpg, .jpeg)
                    <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                </b-button>
            </div>
        </div>

        <form ref="form">
            <input
            id="files"
            type="file"
            name="file"
            ref="uploadInput"
            accept="image/*"
            :multiple="false"
            @change="detectFiles($event)" />
        </form>
        <b-progress v-if="uploading && !uploadEnd" :value="progressUpload" max="100" show-progress animated></b-progress>

        <img v-if="uploadEnd" :src="downloadURL" style="max-height: 500px" />

        <div v-if="uploadEnd">
            <b-button v-if="!showMode" variant="danger" color="error" @click="deleteImage()">
                Deletar Imagem
            </b-button>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase'
import 'firebase/firestore'
// var storage = firebase.app().storage("gs://my-custom-bucket");
var firestorage = firebase.storage();

export default {
    name: 'uploadImgInput',
    props: ['curso_id', 'item', 'showMode'],
    data () {
        return {
            progressUpload: 0,
            uploadTask: '',
            uploading: false,
            uploadEnd: false,
            downloadURL: '',
            msgErro: false,
            img_path: ''
        }
    },
    methods: {
        selectFile () {
            this.$refs.uploadInput.click()
        },
        detectFiles (e) {
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload(fileList[x])
            })
        },
        upload (file) {
            // Verificando o tamanho e o tipo do arquivo
            this.msgErro = ''
            let fileSize= file.size
            let fileType= file.type.split("/")[0]
            if(fileSize <= 1000000 && fileType == 'image'){
                this.uploading = true
                this.uploadTask = firestorage.ref(this.img_path).put(file)
            } else {
                if(fileType !== 'image'){
                    console.log("Erro: o arquivo selecionado não é uma imagem")
                    this.msgErro = 'formato'
                } else {
                    if(fileSize > 1000000){
                        console.log("Erro: o arquivo selecionado é maior que 1 Mb")
                        this.msgErro = 'tamanho'
                    }
                }
            }
            
        },
        deleteImage () {
            firestorage
                .ref(this.img_path)
                .delete()
                .then(() => {
                    this.uploading = false
                    this.uploadEnd = false
                    this.downloadURL = ''
                    this.progressUpload = 0
                    this.msgErro = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
            this.$refs.form.reset()
        }
    },
    watch: {
        // Observador do carregamento da imagem
        uploadTask: function () {
            this.uploadTask.on('state_changed', sp => {
                this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
            },
            null,
            () => {
                    this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                    this.uploadEnd = true
                    this.downloadURL = downloadURL
                    this.$emit('downloadURL', downloadURL)
                })
            })
        }
    },
    mounted(){
        this.img_path = 'testes'

        // Verificando qual o item e atribuindo o id do curso
        if(this.item == 'item_4_1'){
            this.img_path = 'item_4_1/' + this.curso_id 
        }
        if(this.item == 'item_10'){
            this.img_path = 'item_10/' + this.curso_id
        }

        firestorage
            .ref()
            .child(this.img_path)
            .getDownloadURL()
            .then((url) => {
                this.uploadEnd = true
                this.downloadURL = url
            })
            .catch((error) => {
                // Handle any errors
                if( error.code == 404){
                    console.log('Tranquilo, a imagem não existe mesmo')
                } else {
                    console.log('Erro ao recuperar a imagem: ' + error)
                }
                
            });
    }
}
</script>

<style scoped>
/* .progress-bar {
    margin: 10px 0;
} */
input[type="file"] {
    position: absolute;
    clip: rect(0,0,0,0);
}
</style>